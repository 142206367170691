/* Apply flexbox to the entire page */
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Ensure main content takes all available space */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Push the footer to the bottom */
.main-content {
  flex: 1;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}