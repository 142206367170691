/* General Typography */
.containerPflege {
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  background-color: #ebecee !important;
}

.pflegeH1, .pflegeH2 {
  font-weight: 700;
}

.PflegeH1 {
  font-size: 2.5rem;
  color: #006D5B !important;

}

.PflegeH2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color:black !important;
}

.pflegeH2 {
  font-size: 1.5rem;
}

.pflegeH2{
  color: #006D5B;
}

.text-primary.PflegeH5 {
  color: #006D5B !important; /* Custom color */
  font-weight: 700;
}


.pflegePara {
  font-size: 1.125rem;
}

.PflegeButton{
  color: #f7fbfb !important;
  background-color:#006D5B !important;
  width: auto;
  min-width: 120px; 
  transition: width 0.3s ease;
}


.text-justify {
  text-align: justify;
}

/* Container Styling */
.containerPflege {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.nursePic{
  width:800px;
}
/* Card Style for Key Points & Course Overview */
.bg-light {
  background-color: #ffffff !important;
}

.shadow-sm {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.p-3 {
  padding: 1.5rem !important;
}

.rounded {
  border-radius: 8px !important;
}

/* List Group for Job Opportunities */
.list-group-item {
  font-size: 1.125rem;
  background-color: #f8f9fa;
  border: none;
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

.list-group-item.active {
  background-color: #006D5B;
  color: #fff;
}

/* Form Styling */
.PflegeFormControl {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.PflegeFormLabel {
  font-weight: 600;
  font-size: 1.125rem;
}

.PflegeFormControl {
  padding: 0.8rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.PflegeFormControl:focus {
  border-color:#006D5B;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Hero Section */
.display-4{
  margin-top: 10vh;
  color:#006D5B;
}

header {
  margin-bottom: 3rem;
}

header h1 {
  font-size: 3rem;
}

header p {
  font-size: 1.25rem;
}

.pflegeCourse{
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.pflegeCourse:hover{
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Course Overview - Adjusting Grid for Smaller Screens */
/* Unique Styles for Key Benefits Section */
.key-benefits .row.g-3 .col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.key-benefits .row.g-3 .col-md-4 {
  flex: 0 0 50%;
  max-width: 50%;
}

.Pflegebrochure {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  color: #fff;
  background-color: #006D5B; /* Soft green background */
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth hover transition */
  width: 10em;
  margin-bottom: 2em;
}

.Pflegebrochure:hover {
  background-color: #006D5B; /* Slightly darker green on hover */
  transform: translateY(-2px); /* Button raises slightly on hover */
}

.Pflegebrochure:active {
  transform: translateY(2px);  /* Slight depression effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Focus effect for accessibility */
.Pflegebrochure:focus {
  outline: none;
}

@media (max-width: 576px) {
  .key-benefits .row.g-3 .col-md-6,
  .key-benefits .row.g-3 .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Unique Styles for Course Overview Section */
.course-overview .row.g-3 .col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.course-overview .row.g-3 .col-md-4 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 576px) {
  .course-overview .row.g-3 .col-md-6,
  .course-overview .row.g-3 .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  p {
    font-size: 1rem;
  }

  .form-label {
    font-size: 1rem;
  }

  .btn {
    font-size: 1rem;
  }
}
