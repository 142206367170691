/* General Styling */
body{
  background-color: #ebecee !important;
}

.Homecontent {
  padding: 20px;
}

/* First Section */
.containerHomeFirstSection {
  padding-top: 15vh;
}

.list-unstyled{
  font-weight:500;
  font-style: italic;
  font-size: x-large;
}

.HomecontentDescription {
  margin: 20px 0;
}

.HomecontentDescription h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.HomecontentDescription h5 {
  font-size: 1.5rem;
  color: #555;
}

.HomecontentDescription ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 1.5rem;
  color: #444;
}

.HomecontentDescription ul li {
  margin-bottom: 10px;
}

.HomecontentDescription .btn {
  background-color: #6fa06f;
  color: #fff;
  padding: 10px 20px;
  border-radius: 30px;
  transition: background-color 0.3s ease;
}

.HomecontentDescription .btn:hover {
  background-color: #5e8e5e;
  color: #fff;
}

.testimonialsHeading{
  text-align:center;
}

.pictures img {
  width: 100%;
  padding-right:2rem;
  max-width: 950px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Course Cards Section */
.courseList {
  padding: 50px 0 0 0;
}

.courseCards {
  padding: 10px;
  display: flex;
}

.card-link {
  text-decoration: none;
}

.card-link:hover {
  text-decoration: none;
}

/* Ensuring all course cards are equal height and align properly */
.course-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  width:400px;
  height:250px; /* Full height to ensure cards are of equal height */
  margin:0 auto;
}

/* Flexbox for equal height */
.course-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* German flag color for heading box */
.course-card .german-flag {
  background: linear-gradient(to right, #000000, #FF0000, #FFD700);
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 15px;
}

/* Course card list styling */
.course-card ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}

.course-card ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Welcome Note */
.welcomeNote {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
}

/* Disable Bootstrap's default sliding */
.carousel-item {
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out !important;
  transform: rotateY(0deg); /* Start position: no flip initially */
  backface-visibility: hidden; /* Prevent the back face of items from showing */
  perspective: 1000px; /* Enable 3D perspective */
  opacity: 1;
}

/* Define flip animation for the next and previous items */
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg); /* Flip the next/previous item */
  opacity: 0; /* Hide next/previous items during transition */
}

/* Make sure the current active item stays visible and fully flipped */
.carousel-item.active {
  transform: rotateY(0deg); /* Fully visible */
  opacity: 1; /* Fully opaque */
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out !important;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent any overflow */
}

/* Prevent any sliding effect from default Bootstrap functionality */
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: rotateY(0deg); /* No sliding, no horizontal movement */
  opacity: 1;
}


/* Responsive styling for carousel */
@media (max-width: 768px) {
  .carousel-item .col-12 {
    padding: 5px;
  }
}

/* Course Certificate Section */
.courseCertificate {
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 3rem 2rem;
}

.courseCertificate .elevateHeading {
  font-size: 1.75rem;
  font-weight: bold;
}

.courseCertificateDesc {
  font-size: 1.2rem;
  color: #444;
}

.courseCertificate .btn {
  background-color: #6fa06f;
  color: #fff;
  border-radius: 30px;
  margin-top: 1rem;
}

.courseCertificate .btn:hover {
  background-color: #5e8e5e;
}

.courseCertificate .row {
  display: flex;
  align-items: center; /* Centers the content vertically */
}

.courseCertificate .col-lg-6 {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the text column */
}

.courseCertificate img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* AOS Animations */
[data-aos] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos].aos-animate {
  opacity: 1;
}

[data-aos="fade-up"] {
  transform: translateY(20px);
}

[data-aos="fade-left"] {
  transform: translateX(20px);
}

[data-aos="zoom-in"] {
  transform: scale(0.8);
}

/* Typewriter effect styling */
.typewriter {
  font-family: 'Courier New', Courier, monospace;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid black;
  animation: typing 3.5s steps(40, end), blink 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  from { border-color: transparent }
  to { border-color: black }
}

/* Responsive Styling */
@media (max-width: 1000px) {

  .HomecontentDescription h3 {
    font-size: 1.5rem;
  }

  .HomecontentDescription h5 {
    font-size: 1rem;
  }
}

@media (max-width: 900px){
  .row .courseCards {
    flex: 0 0 100%; /* Full width on small screens */
    max-width: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1350px) {
  .row .courseCards {
    flex: 0 0 48%; /* Two cards per row */
    max-width: 48%;
  }
}

/* Three cards per row for larger screens */
@media (min-width: 1351px) {
  .row .courseCards {
    flex: 0 0 32%; /* Three cards per row */
    max-width: 32%;
  }
}

/* Hover effects */
.course-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Puppy Icon Styling */
.floating-puppy {
  position: fixed;
  right: 20px;
  bottom: 50%;
  cursor: pointer;
  background-color: #6fa06f;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.floating-puppy:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Modal Overlay Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding:5em;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it’s above other content */
}

.modal-content {
  margin:10em 0 3em;
  background-color: #fff;
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Default padding */
  width: 90%; /* Responsive width */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  position: relative; /* To position close button */
  text-align: center; /* Center content text */
  max-height: 80vh; /* Set maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.modal-content img {
  max-width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  margin: 20px 0; /* Space between images and text */
}

.puppy{
  padding-top:2em;

}

.puppy img{
  margin:0 auto;
  width:70%;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  top: 15px; /* Adjust top position */
  right: 20px; /* Adjust right position */
  font-size: 1.5rem; /* Size of the close icon */
  color: #333; /* Color of the close icon */
  transition: color 0.3s ease; /* Smooth transition on hover */
}

.close-modal:hover {
  color: #ff0000; /* Change color on hover */
}


/* Modal Text Styling */
.modal-content h4 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
}

.modal-content p {
  font-size: 1.1rem;
  color: #555;
}
