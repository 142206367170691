.contactContainer {
  padding-top: 20vh;
  padding-bottom: 25px;
  background: #f8f9fa; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 800px; /* Reduced width */
  margin: 0 auto;
  margin-bottom: 20px;
}

.contactIcons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.icon-box {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Padding for button-like appearance */
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
  width: calc(33.3333% - 20px); /* Three equal columns with space between */
  text-align: center;
  justify-content: center;
}

.icon-box:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.icon {
  margin-right: 10px;
  font-size: 24px; /* Adjust size as needed */
}

.contactDetails {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.col-md-4, .col-md-6, .col-md-12 {
  box-sizing: border-box;
  padding: 0 10px;
}

.col-md-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.mb-3 {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea.form-control {
  resize: vertical;
}

.recaptcha-container {
  display: flex;
  justify-content: flex-start; /* Align left with the message box */
  margin-bottom: 15px;
}

.submit-button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.submit-button button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button button:hover {
  background-color: #0056b3;
}

.thank-you-message {
  text-align: center;
  color: green;
  margin-top: 20px;
  margin-bottom:auto;
}

@media (max-width: 850px) {
  .contactIcons {
    flex-direction: column; /* Stack icons vertically on smaller screens */
    align-items: center; /* Center align */
  }

  .icon-box {
    width: 100%; /* Full width for icons */
    margin-bottom: 10px; /* Space between stacked icons */
  }

  .contactContainer{
    padding-left: 2em;
    padding-right: 2em;
  }

  .form-control{
    @media (max-width: 768px) {
      .contactIcons {
        flex-direction: column; /* Stack icons vertically on smaller screens */
        align-items: center; /* Center align */
      }
    
      .icon-box {
        width: 100%; /* Full width for icons */
        margin-bottom: 10px; /* Space between stacked icons */
      }
    }
    @media (max-width: 800px) {
      .icon-box {
        width: 100%; /* Full width for icons */
        margin-bottom: 10px; /* Space between stacked icons */
      }
    }

    #contactForm::placeholder{
      font-size: small;
    }
     
  }
}
