/* Apply the background image to a wrapper element */
.background-wrapper {
  background-image: url('../library.webp'); /* Replace with your image path */
  background-size: cover;
  background-attachment: fixed; /* Make the background image fixed */
  background-position: center;
  min-height: 100vh; /* Ensure the wrapper covers the full viewport height */
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  margin-top:8vh;
  padding:0;
}

.courseContainer {
  flex: 1; /* Allow the container to grow and fill available space */
  margin: 100px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px; /* Center the container and give it a max width */
  overflow-y: auto; /* Enable vertical scrolling inside the container */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent background to the container */
  margin-bottom:25px;
}

.cardBox {
  display: flex;
  align-items: stretch; /* Make all cardBox elements the same height */
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rounded-pill {
  border-radius: 50px; /* Adjust as needed for the desired rounded appearance */
}

.h2-custom {
  font-size: 2.5rem; /* Adjust font size to make the h2 element bigger */
  padding: 20px; /* Adjust padding for larger h2 element */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.h2-custom:hover {
  transform: scale(1.05); /* Scale up on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.custom-card {
  background-color: #f9f9f9; /* Light grey background for professional look */
  color: #333; /* Dark grey text color */
  font-family: 'Arial', sans-serif; /* Professional font */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: none; /* Remove border for cleaner look */
  display: flex;
  flex-direction: column; /* Ensure card contents are arranged vertically */
  height: 100%;
}

.custom-card:hover {
  transform: scale(1.03); /* Slightly enlarge card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.btn-primary {
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.btn-primary:hover {
  background-color: #007bff; /* Blue hover background color */
  color: #fff; /* White hover text color */
}

.custom-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  /* Add any other custom styles as needed */
}

.card-title {
  font-weight: 600;
}

.footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  width: 100%;
}

/* Base styles for the button */
.brochure {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  color: #fff;
  background-color: #282c34; /* Soft green background */
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth hover transition */
  width: 10em;
  margin: 0 auto;
  margin-bottom: 2em;
}

/* Hover effect for the button */
.brochure:hover {
  background-color: #282c34; /* Slightly darker green on hover */
  transform: translateY(-2px); /* Button raises slightly on hover */
}

.brochure:active {
  transform: translateY(2px);  /* Slight depression effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Focus effect for accessibility */
.brochure:focus {
  outline: none;
}

@media (max-width: 600px) {
  .btn.brochure {
    width: 100%;  /* Make the button take full width on small screens */
    padding: 12px;  /* Adjust padding for better touch targets */
    font-size: 18px;  /* Slightly increase font size for readability */
  }
}

