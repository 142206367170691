
/* Container for the entire translation section */
.translationContainer {
  max-width: 1200px;
  margin: 0 auto;
  margin-top:20vh;
  padding:0 20px 25px;
  background-color: #f0f4f8;
}
/* translation.css */


/* Intro section styling */
.introSection {
  margin-bottom: 40px;
}

.introTitle {
  font-size: 2.5rem;
  color: #2C3E50; /* Dark color for a professional look */
  text-align: center;
  margin-bottom: 20px;
}

.introText {
  font-size: 1.1rem;
  color: #34495E; /* Slightly lighter color for readability */
  text-align: center;
  margin-bottom: 30px;
}

.infoSections {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
}

.infoSection {
  flex: 1;
  background-color: #FFFFFF;
  border: 1px solid #BDC3C7;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.offerTitle,
.commonDocsTitle {
  font-size: 1.5rem;
  color: #2980B9; /* Blue color for headings */
  text-align: center;
  margin-bottom: 20px;
}

.offerList,
.commonDocsList {
  list-style: none;
  padding: 0;
  text-align: center;
}

.offerList li,
.commonDocsList li {
  font-size: 1.2rem;
  color: #2C3E50; /* Dark color for list items */
  margin-bottom: 10px;
}

.offerIcon {
  color: #2980B9; /* Blue color for icons */
  margin-right: 10px;
}

.contactText {
  font-size: 1.1rem;
  color: #34495E;
  text-align: center;
}

/* Steps section styling */
.translationSteps {
  background-color: #ECF0F1; /* Light grey background for the steps section */
  border-radius: 10px;
  padding: 20px;
}

.stepsTitle {
  font-size: 2rem;
  color: #2C3E50;
  text-align: center;
  margin-bottom: 20px;
}

.stepsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.step {
  display: flex;
  align-items: start;
  background-color: #FFFFFF;
  border: 1px solid #BDC3C7; /* Light border */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stepIcon {
  color: #2980B9; /* Blue color for icons */
  margin-right: 20px;
}

.stepContent {
  max-width: 800px;
}

.stepContent h4 {
  font-size: 1.3rem;
  color: #2C3E50;
  margin-bottom: 10px;
}

.stepContent p {
  font-size: 1rem;
  color: #34495E;
}
