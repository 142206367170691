.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears on top of other content */
  margin-top:5em;
}

.popup-content {
  position: relative;
  background-color: #fff;
  margin: 5% auto; /* Center the popup vertically */
  padding: 20px;
  border-radius: 5px;
  max-width: 90%; /* Adjusted for responsiveness */
  max-height: 80%; /* Adjusted for responsiveness */
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #aaa;
  transition: color 0.2s; /* Transition effect for hover */
}

.close:hover {
  color: #333; /* Darker color on hover for close button */
}

.container {
  margin-top: 20px;
  padding-top: 2em;
}

.courseLevel {
  padding: 10px;
  border: 1px solid #ddd; /* Border for the course level */
  background-color: #c9dcef; /* Light background color */
  border-radius: 5px 5px 0 0; /* Rounded corners on the top only */
  margin-bottom: -1px; /* Overlap the table border */
}

.table {
  width: 100%;
  border: 1px solid #ddd; /* Outer border for the table */
  margin-bottom: 0; /* Remove bottom margin */
  border-top: none; /* Remove top border to connect with courseLevel */
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #ddd; /* Border for table cells */
  padding: 8px; /* Added padding for table cells */
}

.batchDetail {
  margin-bottom: 10px; /* Add bottom margin between batches */
}

.btn {
  margin-top: 0; /* Adjusted top margin for button */
  width: 5.5rem; /* Make button slightly less than 100% width */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  margin-bottom: 1.5em;
  transition: background-color 0.3s, transform 0.2s; /* Transition for background and scale */
}

.btn:hover {
  background-color: #007bff; /* Change button background on hover */
  color: white; /* Change text color on hover */
  transform: scale(1.05); /* Scale effect on hover */
}

/* Additional styles for responsiveness */
@media (max-width: 768px) {
  .popup {
    padding-bottom: 40px; /* Bottom padding for mobile */
    margin-top:2em;
  }
  .popup-content {
    margin-top: 10%; /* Increased top margin for smaller screens */
    max-width: 90%; /* Ensure it takes up most of the screen */
    max-height: 80%; /* Allow for more height */
    padding: 15px; /* Reduced padding for smaller screens */
    overflow-y: auto; /* Ensure scrolling if content overflows */
    border-radius: 8px; /* Slightly larger border radius */
  }

  .courseLevel {
    font-size: 0.9rem; /* Slightly smaller font for smaller screens */
  }

  .table {
    font-size: 0.9rem; /* Slightly smaller font for smaller screens */
  }

  .btn {
    width: 100%; /* Full width button for smaller screens */
    text-align: center; /* Center text in button */
  }
}

@media (max-width: 480px) {
  .popup-content {
    padding: 10px; /* Further reduce padding on very small screens */
    margin-top:0.5em;
  }
  
  .close {
    font-size: 20px; /* Smaller close button */
  }
  
  .courseLevel {
    font-size: 0.8rem; /* Smaller font for very small screens */
  }

  .table {
    font-size: 0.8rem; /* Smaller font for very small screens */
  }
}
