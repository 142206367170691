/* Container for the carousel */
.carousel-testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  width: 850px; /* Increased width for a wider card */
  position: relative;
  height: 400px; /* Height remains suitable for the larger cards */
  margin-top: 0;
}

/* Wrapper to hold the cards */
.carousel-testimonial-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}

/* Styling for each card */
.carousel-testimonial-card {
  display: flex;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transform: scale(1);
  transition: transform 0.5s ease, opacity 0.5s ease-in-out;
  padding: 20px;
  margin: 10px;
  width: 100%; /* Make the card take the full width */
  cursor: pointer;
}

/* Hover effect on the card */
.carousel-testimonial-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

/* Apply a small animation effect for card entry */
.carousel-testimonial-card.animated-card {
  animation: fadeInUp 0.8s ease-out;
}

/* Styling for the image container */
.carousel-testimonial-image-container {
  flex: 1;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* No padding to avoid exceeding the container size */
  width: 250px;  /* Set width to a fixed size */
  height: 250px; /* Set height to a fixed size to maintain square aspect ratio */
  overflow: hidden; /* Ensures the image doesn't overflow */
}

/* Styling for the image */
.carousel-testimonial-image {
  width: 100%;  /* Make the image take up the full width of the container */
  height: 100%;  /* Make the image take up the full height of the container */
  object-fit: cover;  /* Ensures the image fills the container without distortion */
}

/* Styling for the content area */
.carousel-testimonial-content {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* Added relative position for button alignment */
  height: 100%; /* Ensure the content takes full height */
}

/* Name styling */
.carousel-testimonial-name {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Feedback container */
.carousel-testimonial-feedback-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling if the feedback is long */
  flex-grow: 1; /* Allow feedback to take remaining space */
  max-height: 200px; /* Limit the height for the feedback area */
}

/* Feedback styling */
.carousel-testimonial-feedback {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Read More/Read Less button styling */
.carousel-testimonial-readmore {
  align-self: flex-start;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  position:absolute;
  bottom:-1.1em;
}

.carousel-testimonial-readmore:hover {
  background: #0056b3;
}

/* Circle indicators styling */
.carousel-indicators {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.carousel-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-indicator.active {
  background-color: #007bff;
}

.carousel-indicator:hover {
  background-color: #007bff;
}

/* Keyframe for card fade-in animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries for Responsiveness */

/* For screens smaller than 1024px (tablets, small screens) */
@media (max-width: 1024px) {
  .carousel-testimonial-container {
    width: 100%; /* Full width for smaller screens */
    padding: 0 20px; /* Add padding for spacing */
  }

  .carousel-testimonial-image-container {
    width: 200px; /* Slightly smaller image container */
    height: 200px;
  }

  .carousel-testimonial-image {
    width: 100%;
    height: 100%;
  }

  .carousel-testimonial-name {
    font-size: 1.4rem;
  }

  .carousel-testimonial-feedback {
    font-size: 0.95rem;
  }

  .carousel-testimonial-readmore {
    font-size: 0.9rem;
  }
}

/* For screens smaller than 768px (tablets, mobile devices) */
@media (max-width: 768px) {
  .carousel-testimonial-container {
    width: 100%;
    height: auto;
    padding: 10px; /* Reduce padding */
  }

  .carousel-testimonial-card {
    flex-direction: column; /* Stack image and content vertically */
    padding: 15px;
  }

  .carousel-testimonial-image-container {
    width: 150px; /* Smaller image size for mobile */
    height: 150px;
  }

  .carousel-testimonial-name {
    font-size: 1.2rem;
  }

  .carousel-testimonial-feedback {
    font-size: 0.9rem;
    max-height: 180px; /* Adjust max height for feedback */
  }

  .carousel-testimonial-readmore {
    font-size: 0.85rem;
    margin-top: auto; /* Ensure "Read More" button is aligned to the bottom */
  }
}

/* For screens smaller than 480px (phones) */
@media (max-width: 480px) {
  .carousel-testimonial-container {
    padding: 10px;
  }

  .carousel-testimonial-card {
    padding: 10px;
  }

  .carousel-testimonial-image-container {
    width: 120px; /* Even smaller image for phones */
    height: 120px;
  }

  .carousel-testimonial-name {
    font-size: 1rem;
  }

  .carousel-testimonial-feedback {
    font-size: 0.85rem;
    max-height: 150px; /* Further reduce feedback height */
  }

  .carousel-testimonial-readmore {
    font-size: 0.8rem;
    margin-top: auto; /* Ensure "Read More" button stays at the bottom */
  }
}
