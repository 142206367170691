.Registerpage {
  font-family: Arial, sans-serif;
  padding-bottom: 25px;
  margin-top: 0;
  background-color: #f0f4f8;
}

.backgroundPicture {
  background-image: url('../backimage.webp');
  background-size: cover;
  background-position: center;
  height: 50vh;
  position: relative;
}

.enrollText {
  text-align: center;
  padding-bottom: 2em;
  padding-top: 15px;
}

.formContainer {
  margin: 0 auto;
  padding: 2em;
  max-width: 800px;
  background-color: #d3d3d3;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-select,
.form-control,
.countryDrop__control {
  width: 100%;
  padding: 0.75em;
  margin-bottom: 1em;
  border: none; /* Remove default border */
  border-radius: 4px;
}

.countryDrop__control {
  border: 1px solid #ccc; /* Keep a border for the react-select control */
}

.personalDetails,
.contactDetails,
.nameDetails,
.detailsCity,
.courseDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.titleSelect {
  flex: 1;
}

/* Styling for buttons */
.form-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

.button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 1em 2em;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 30%; /* Responsive width for buttons */
  min-width: 90px; /* Ensures buttons don't get too small */
  max-width: 200px; /* Limits max size */
  margin: 0.5em 0.2em;
}

.form-buttons .button.active {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-buttons .button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-buttons .button:hover {
  background-color: #0056b3;
}

.continueButton {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: auto;
  min-width: 120px; /* You can adjust this value as needed */
  transition: width 0.3s ease;
}

.submitButton {
  width: 10em;
  height: 3em;
  font-size: 1.3em;
  font-weight: 500;
}

.thank-you-message {
  margin: 0 auto;
}

/* Input Validation Styles */
.form-select.inValid,
.form-control.inValid,
.form-check-input.inValid {
  border: 2px solid red; /* Red border for invalid input */
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); /* Shadow effect */
}

.form-control.inValid:focus {
  border-color: #ff6347; /* Lighter red border when input is focused */
  box-shadow: 0 0 8px rgba(255, 99, 71, 0.7); /* Adjusted shadow effect on focus */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .formContainer {
    padding: 1.5em;
    margin: 0 15px;
  }

  .button {
    padding: 0.6em 1.5em;
    width: 45%; /* Adjusts to a wider size on smaller screens */
    font-size: 0.9em;
  }
}

@media (max-width: 1100px) {
  .formContainer {
    margin: 0 30px;
  }
}
