.faq-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 25px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.faq-item {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.faq-question {
  background-color: #f4f4f4;
  padding: 15px;
  cursor: pointer;
  position: relative;
  font-size: 1.1rem;
  color: #555;
}

.faq-question.active {
  background-color: #e0e0e0;
}

.arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9rem;
  color: #555;
}

.faq-answer {
  display: none;
  padding: 15px;
  background-color: #fff;
  color: #666;
}

.faq-answer.show {
  display: block;
}
