/* aboutUs.css */

.aboutContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20vh 4vh 3vh;
  background-color: #f0f4f8;
  font-family: Arial, sans-serif;
  color: #333;
}

.aboutHeader {
  text-align: center;
  margin-bottom: 40px;
}

.aboutHeader h1 {
  font-size: 2.5rem;
  color: #0056b3; /* Professional blue color */
}

.aboutHeader p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.aboutSection {
  margin-bottom: 30px;
}

.aboutSection h2 {
  font-size: 2rem;
  color: #003d7a; /* Darker blue for section headings */
  border-bottom: 2px solid #0056b3;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.aboutSection p {
  font-size: 1.1rem;
  line-height: 1.6;
}

#aboutFooter {
  text-align: center;
  margin-top: 40px;
  background-color: beige;
}

#aboutFooter h3 {
  font-size: 1.8rem;
  color: #0056b3; /* Consistent color with header */
  font-weight: bold;
  
}

@media (max-width: 1200px){
  .aboutContainer{
    padding:200px 50px 50px;
  }
}
