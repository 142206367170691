/* General Styles */
.navbar {
  padding: 10px 20px;
  background-color: rgba(187, 190, 195, 0.98) !important; 
  color: #111010 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
}



/* Logo Styling */
.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  margin-right: 10px;
}

/* Navigation Links */
.navbar-nav {
  display: flex;
  gap: 15px;
}

.navbar-nav .nav-link {
  font-weight: bold;
  text-decoration: none;
  padding: 8px 12px;
  transition: transform 0.3s ease, color 0.3s ease;
  border-radius: 4px; /* Slightly rounded edges for links */
}

.navbar-nav .nav-link:hover {
  transform: scale(0.95); /* Shrinks slightly on hover */
  color: #00b894; /* Light green for hover state */
}

.navbar-nav .nav-link.active {
  text-decoration: underline;
  color: #0c0c0c; /* High contrast white for dark background */
  text-decoration-color: #00b894; /* Matching hover color */
  font-weight: bold;
}

/* Navbar Toggler for Mobile */
.navbar-toggler {
  border-color: #100f0f; /* White border for visibility */
}

.navbar-toggler-icon {
  background-color: #0a0a0a; /* Visible icon on dark background */
  width: 20px;
  height: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column; /* Stack links vertically */
    gap: 10px; /* More spacing for easier tapping on mobile */
    align-items: center; /* Center links in mobile view */
  }

  .nav-link {
    font-size: 1rem; /* Slightly larger text for touch devices */
    width: 100%; /* Full-width clickable area */
    text-align: center; /* Center-align text */
    padding: 12px; /* Add padding for touch-friendly areas */
  }

  .navbar-toggler-icon {
    width: 25px;
    height: 25px;
  }
}

/* Navbar Collapse Animation */
.collapse:not(.show) {
  display: none; /* Ensures smoother transition in some browsers */
}

.collapse.show {
  display: block;
  transition: max-height 0.3s ease-in-out; /* Smooth toggle for collapse */
}
