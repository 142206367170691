/* Ensure the entire page takes at least the full height of the viewport */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Main content should take all available space */
#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Footer styling */
footer {
  background-color: #282c34; /* Darker background for elegance */
  color: #ffffff; /* White text for contrast */
  padding: 60px 0; /* Increased padding for spaciousness */
  font-family: 'Open Sans', sans-serif; /* Modern and readable font */
  margin-top: auto; /* Footer stays at the bottom */
  position: relative; /* Positioning for potential decorative elements */
}

footer .container {
  max-width: 1200px; /* Limit the width of the footer content */
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  flex-wrap: wrap; /* Wrap content on smaller screens */
}

/* Quick Links Section */
footer h5 {
  font-size: 1.8rem; /* Slightly larger font for headings */
  margin-bottom: 20px; /* Space below headings */
  font-weight: 600; /* Semi-bold for a modern look */
  text-transform: uppercase; /* Uppercase for emphasis */
  letter-spacing: 1px; /* Slight spacing between letters for clarity */
  text-align: center; /* Center align headings */
}

.quick-links {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of columns */
  justify-content: center; /* Center the quick links */
  margin-bottom: 30px; /* Add space below quick links section */
}

.quick-links .column {
  display: flex;
  flex-direction: column;
  margin: 0 20px; /* Space between columns */
  text-align: center; /* Center align text in columns */
}

.quick-links .footer-link {
  color: #ffffff; /* White text color */
  text-decoration: none;
  padding: 10px 0;
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Subtle bottom border */
  transition: color 0.3s ease, border-bottom 0.3s ease; /* Smooth hover transition */
  margin-bottom: 10px; /* Space between links */
}

.quick-links .footer-link:hover {
  color: #f9d342; /* Soft yellow color on hover */
  border-bottom: 1px solid #f9d342; /* Yellow border on hover */
  transform: translateX(5px); /* Slight right movement on hover */
}

/* Connect with Us Section */
.footer-icons {
  display: flex;
  justify-content: center; /* Center icons horizontally */
  gap: 15px; /* Space between icons */
}

.footer-icons a {
  color: #ffffff; /* White icon color */
  font-size: 1.8rem; /* Size of the icons */
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Circular icons */
  background-color: rgba(255, 255, 255, 0.1); /* Slight background */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effect */
}

.footer-icons a:hover {
  background-color: #f9d342; /* Yellow background on hover */
  transform: scale(1.1); /* Slight zoom effect */
  color: #282c34; /* Dark icon color on hover */
}

/* Location Info Section */
.location-info {
  margin-top: 20px;
  text-align: center; /* Center align location info */
}

.location-info div {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  font-size: 1rem; /* Font size for location text */
  color: #b0b0b0; /* Subtle grey color */
}

.location-info div svg {
  font-size: 1.2rem; /* Slightly smaller icon size */
  color: #f9d342; /* Yellow icons for visual appeal */
}

/* Footer Bottom Border */
footer:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, #f9d342, #ff914d); /* Gradient border on top */
}

footer .text-center {
  text-align: center;
}

footer .copyright {
  font-size: 1.2em;
  color: #666;
  margin-top: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align on smaller screens */
  }

  .quick-links {
    margin-bottom: 30px; /* Add space below links */
  }

  .footer-icons {
    justify-content: center; /* Center icons on mobile */
  }

  .location-info {
    text-align: center; /* Center location info on smaller screens */
  }
}
